import React from 'react';
import cx from 'classnames';

import { ModuleWrapper } from '../moduleWrapper';

const TableRow = ({ cells, isFirst }) => {
  return (
    <div
      className={cx(
        'grid grid-flow-row md:text-xl md:grid-flow-cols md:grid-cols-6 md:gap-x-6 md:py-6 md:text-center',
        {
          'font-x-bold': isFirst,
        }
      )}
    >
      {cells.map((item) => (
        <div>{item}</div>
      ))}
    </div>
  );
};

const InfoTable = ({ title, table, config }) => {
  return (
    <ModuleWrapper {...config} className="px-gutter">
      <div className="bg-red text-white rounded-2xl px-10 py-16  max-w-7xl mx-auto">
        <h2 className="text-center blockH1">{title}</h2>
        <div className="grid grid-flow-col gap-y-4 my-16 md:grid-flow-row md:divide-y md:gap-y-0">
          {table.rows.map((item, index) => (
            <TableRow {...item} isFirst={index < 1} />
          ))}
        </div>
      </div>
    </ModuleWrapper>
  );
};

export default InfoTable;
